<template>
  <div class="deviceEdit">
    <div class="mainTitle textAverage">{{title}}</div>
    <div class="form">
      <div class="column">
        <div class="row">
          <div class="colLabel">
            <label class="textSmall sideFloat">Cliente: </label>
          </div>
          <div class="colImput">
            <div v-if="!unityEdit" class="selectConfig">
              <div class="selectBtn">
                <div class="eventClickArea" @click="showPopupToSearch('Clientes')"></div>
                <input class="input textSmall" type="text" v-model="client.name" disabled>
                <span class="textErro" style="margin: 3px 0 0 15px">{{errorMsgClient}}</span>
                <span class="pointer">
                  <img class="smallIconBtn" src="~@/assets/icons/outros/search.svg" alt="">
                </span>
              </div>
            </div>
            <div v-else class="selectConfig">
              <div class="selectBtn">
                <input class="input textSmall" :class="{inputDisabled: true}" type="text" v-model="client.name" disabled>
                <span class="textErro" style="margin: 3px 0 0 15px">{{errorMsgClient}}</span>
                <span class="pointerDisabled">
                  <img class="smallIconBtn" src="~@/assets/icons/outros/search.svg" alt="">
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="colLabel">
            <label class="textSmall sideFloat">Unidade: </label>
          </div>
          <div class="colImput">
            <div v-if="!unityEdit" class="selectConfig">
              <div class="selectBtn">
                <div class="eventClickArea" @click="showPopupToSearch('Unidades')"></div>
                <input class="input textSmall" type="text" v-model="unity.name" disabled>
                <span class="textErro" style="margin: 3px 0 0 15px">{{errorMsgUnity}}</span>
                <span class="pointer">
                  <img class="smallIconBtn" src="~@/assets/icons/outros/search.svg" alt="">
                </span>
              </div>
            </div>
            <div v-else class="selectConfig">
              <div class="selectBtn">
                <input class="input textSmall" :class="{inputDisabled: true}" type="text" v-model="unity.name" disabled>
                <span class="textErro" style="margin: 3px 0 0 15px">{{errorMsgUnity}}</span>
                <span class="pointerDisabled">
                  <img class="smallIconBtn" src="~@/assets/icons/outros/search.svg" alt="">
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="colLabel">
            <label class="textSmall sideFloat">Hardware ID: </label>
          </div>
          <div class="colImput">
            <input v-if="!newDevice" class="input textSmall" :class="{inputDisabled: !newDevice}" disabled type="text" v-model="hardwareId">
            <div v-else class="selectConfig">
              <div class="selectBtn">
                <div class="eventClickArea" @click="showPopupToSearch('Dispositivos')"></div>
                <input class="input textSmall" type="text" v-model="hardwareId" disabled>
                <span class="pointer">
                  <img class="smallIconBtn" src="~@/assets/icons/outros/search.svg" alt="">
                </span>
              </div>
            </div>
            <span class="textErro" style="margin: 3px 0 0 15px;">{{errorMsgHardwareId}}</span>
          </div>
        </div>
        <div v-if="!newDevice" class="row">
          <div class="colLabel">
            <label class="textSmall sideFloat">Token: </label>
          </div>
          <div class="colImput">
            <input class="input textSmall" :class="{inputDisabled: !newDevice}" disabled type="text" v-model="token">
            <div class="btnBottomInput">
              <div @click="resetToken()" class="btn btnAverageSize btnTypeSave" style="margin-top: 5px; float: right;">
                <label v-if="tokenStatus">Limpar</label>
                <label v-else>Ativar</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="row">
          <div class="colLabel">
            <label class="textSmall sideFloat">Tipo:</label>
          </div>
          <div class="colImput">
            <div class="selectConfig">
              <div class="selectBtn">
                <select class="textSmall" ref="selectType" @change="removeErrorMsg()">
                  <option disabled selected hidden :value="type.id">{{type.name}}</option>
                  <option class="textSmall" v-for="(type, index) in typeList" :key="index" :value="type.id">{{type.name}}</option>
                </select>
                <span class="pointer">
                  <img class="verySmallIconBtn" style="transform: rotate(90deg)" src="~@/assets/icons/arrowWhite.svg" alt="">
                </span>
              </div>
            </div>
            <span class="textErro" style="margin: 3px 0 0 15px">{{errorMsgType}}</span>
          </div>
        </div>
        <div class="row">
          <div class="colLabel">
            <label class="textSmall sideFloat">Descrição:</label>
          </div>
          <div class="colImput">
            <input class="input textSmall" type="text" v-model="description">
            <span class="textErro" style="margin: 3px 0 0 15px;">{{errorMsgDescription}}</span>
          </div>
        </div>
        <div class="row">
          <div class="colLabel">
            <label class="textSmall sideFloat">Coordenadas:</label>
          </div>
          <div class="colImput">
            <input class="input textSmall" type="text" v-model="coordinates">
          </div>
        </div>
      </div>
    </div>
    <div class="tableArea" v-if="device">
      <div class="textSmall labelTableTitle" style="margin-left: 10px">Usuários da Unidade</div>
      <div class="inputContainer">
        <input class="inputSearch textSmall" type="text" placeholder="Pesquisar" v-model="userSearch">
        <img class="searchIcon" src="@/assets/icons/outros/search2.svg" />
      </div>
      <div class="tableInternalArea">
        <table class="tableNormal minSize textSmall">
          <tr>
            <th class="ResponsiveTableHead">Nome</th>
            <th class="ResponsiveTableHead">E-mail</th>
            <th class="ResponsiveTableHead">Permissão</th>
          </tr>
          <tr v-for="(user, index) in userListFilter" :key="index">
            <td>{{user.name}}</td>
            <td>{{user.email}}</td>
            <td v-if="user.accessLevel == accessLevel.DIRETOR">{{"Diretor"}}</td>
            <td v-else-if="user.accessLevel == accessLevel.DESENVOLVIMENTO">{{"Desenvolvimento"}}</td>
            <td v-else-if="user.accessLevel == accessLevel.SUPORTE">{{"Suporte"}}</td>
            <td v-else-if="user.accessLevel == accessLevel.PRODUCAO">{{"Produção"}}</td>
            <td v-else-if="user.accessLevel == accessLevel.VENDAS">{{"Vendas"}}</td>
            <td v-else-if="user.accessLevel == accessLevel.INTEGRADOR">{{"Integrador"}}</td>
            <td v-else-if="user.accessLevel == accessLevel.CLIENTE">{{"Cliente"}}</td>
            <td v-else>{{"Monitoramento"}}</td>
          </tr>
        </table>
      </div>
    </div>
    <SearchInList v-if="showPopup" @close="showPopup = false" :title="searchListTitle" :list="list" @selected="fillInput($event), showPopup = false"></SearchInList>
    <div class="ResponsiveBtnConfigArea" :class="{fixed: !device}">
      <button @click="save()" class="btn btnNormalSize btnTypeSave" style="margin-left: 16px">Salvar</button>
      <button @click="close()" class="btn btnNormalSize btnTypeCancel">Voltar</button>
    </div>
    <PopUp msg="Salvando Dispositivo" :show="loadData" type="Loader"/>
    <PopUp :msg="faultMsg" :show="faultOnUpdateDevice" type="Fault" :onOk="popUpOk"/>
  </div>
</template>

<script>
  import PopUp from '@/views/PopUp/PopUp.vue'
  import UserPermission from "../popups/UserPermission"
  import SearchInList from "../popups/SearchInList.vue"
  import { DateTime } from "luxon"
  import ClientDAO from '@/sys/Control/Client/ClientDAO'
  import UnityDAO from '@/sys/Control/Unity/UnityDAO'
  import DeviceDAO from '@/sys/Control/Device/DeviceDAO'
  import { AccessLevel } from '@/sys/Model/Permissions/AccessLevel'

  function getFormatDate(dateTime) {
    const date = DateTime.fromISO(dateTime).setZone('local').toFormat("dd/MM/yyyy");
    return date;
  }

  export default {
    components: { UserPermission, SearchInList, PopUp },
    props:{
      device: Object,
      newDevice: Boolean,
      unityEdit: Boolean
    },
    methods: {
      async checkHardwareIdUsed(){
        if(this.hardwareId != this.hardwareIdAux) {
          let deviceHardwareId = await DeviceDAO.readDevice(this.hardwareId);
          if(deviceHardwareId) {
              this.errorMsgHardwareId = 'Este hardware ID já está em uso';
          } else {
              this.errorMsgHardwareId = '';
          }
        } else {
          this.errorMsgHardwareId = '';
        }
      },
      compareAddFromClient(user) {
        if(user.addFromClient) {
          return -1;
        } else {
          return 0;
        }
      },
      async save(){
        if(this.client.name == ''){
          this.errorMsgClient = 'Selecione um cliente'
        } else {
          this.errorMsgClient = ''
        }
        if(this.unity.name == ''){
          this.errorMsgUnity = 'Selecione uma unidade'
        } else {
          this.errorMsgUnity = ''
        }
        if(this.hardwareId == ''){
          this.errorMsgHardwareId = 'Selecione um dispositivo'
        } else {
          await this.checkHardwareIdUsed();
        }
        if(this.selectedDeviceType() == 0){
          this.errorMsgType = 'Selecione um tipo'
        } else {
          this.errorMsgType = ''
        }
        if(this.description == ''){
          this.errorMsgDescription = 'Preencha este campo'
        } else {
          this.errorMsgDescription = ''
        }
        if(this.errorMsgClient == '' && this.errorMsgUnity == '' && this.errorMsgHardwareId == '' && this.errorMsgType == '' && this.errorMsgDescription == ''){

          this.loadData = true

          this.client = {
            _id: this.client._id,
            name: this.client.name
          }

          this.unity = {
            _id: this.unity._id,
            cnpj: this.unity.cnpj,
            name: this.unity.name,
            city: this.unity.city,
            state: this.unity.state,
            country: this.unity.country,
            userList: this.unity.userList
          }

          if(this.newDevice) {
            await DeviceDAO.createDevice({
              client: this.client,
              unity: this.unity,
              type: this.selectedDeviceType(),
              hardwareId: this.hardwareId,
              description: this.description,
              gpsCoordinates: this.coordinates,
              systemInfo: null,
              status: null,
              activatedGroups: null,
              createdAt: new Date(),
              updatedAt: new Date(),
              deletedAt: null
            }).catch(e=>this.popUpException(e));
          } else {
            let device = {
              _id: this.device._id,
              client: this.client,
              unity: this.unity,
              type: this.selectedDeviceType(),
              hardwareId: this.hardwareId,
              description: this.description,
              gpsCoordinates: this.coordinates,
              systemInfo: this.systemInfo,
              status: this.status,
              activatedGroups: this.activatedGroups,
              createdAt: this.device.createdAt,
              updatedAt: new Date(),
              deletedAt: this.device.deletedAt
            }

            if(this.unity.cnpj != this.unityAux.cnpj) {
              UnityDAO.addUnityDevice(device).catch(e=>this.popUpException(e));
              if(this.unityAux.cnpj != '') {
                UnityDAO.removeUnityDevice(device, this.unityAux.cnpj).catch(e=>this.popUpException(e));
              }
            }

            await DeviceDAO.updateDevice(device).catch(e=>this.popUpException(e));
          }
          this.loadData = false
          if(!this.faultOnUpdateDevice) {
            this.$emit('close');
          }
        }
      },
      close(){
        this.$emit('close')
      },
      showPopupToSearch(title){
        this.currentList = title
        if(title == 'Clientes'){
          this.list = this.clientList
        }else if(title == 'Unidades'){
          if(this.client.name != ''){
            this.list = this.unityList.filter(unity => unity.client != null && unity.client.name == this.client.name);
          }else{
            this.list = this.unityList
          }
        }else {
          this.list = this.deviceList
        }
        this.searchListTitle = title
        this.showPopup = true
      },
      fillInput(value){
        if(this.searchListTitle == 'Clientes'){
          this.client = value
        }else if(this.searchListTitle == 'Unidades'){
          this.unity = value
        }else {
          this.hardwareId = value
        }
      },
      resetToken(){
        if(this.tokenStatus){
          this.token = 'Desativado'
          this.tokenStatus = false
        }else{
          this.token = 'Ativado: ' + new Date()
          this.tokenStatus = true
        }
      },
      selectedDeviceType(){
        const type = this.typeList.find(type => type.id == this.$refs.selectType.value);
        if(type) {
          return type;
        } else {
          return 0;
        }
      },
      removeErrorMsg(){
        this.errorMsgType = ''
      },
      popUpOk: function(){
        this.faultOnUpdateDevice=false;
        this.$emit('close');
      },
      popUpException: function(e){
        this.loadData = false;
        this.faultMsg=e;
        this.faultOnUpdateDevice=true;
      }
    },
    computed:{
      userListFilter(){
        if(this.userList != null) {
          return this.userList.filter(user => {
            return user.name.toLowerCase().includes(this.userSearch.toLowerCase()) ||
              user.email.toLowerCase().includes(this.userSearch.toLowerCase())
          })
        }
      }
    },
    watch:{
      unity() {
        this.userList = this.unity.userList
        if(this.unity.client != null && this.client.name == '') {
          this.client = this.unity.client
        }
      },
      client() {
        if(!this.loadData && this.changeClient) {
          this.unity = {name: '', cnpj: '', client: {name: ''}}
        } else {
          this.changeClient = true
        }
      },
      unityList(){
        if(this.currentList == 'Unidades'){
          this.list = this.unityList
        }
      },
      clientList(){
        if(this.currentList == 'Clientes'){
          this.list = this.clientList
        }
      },
      deviceList(){
        if(this.currentList == 'Dispositivos'){
          this.list = this.deviceList
        }
      }
    },
    data() {
      return {
        client: {name: ''},
        clientAux: {name: ''},
        changeClient: false,
        unity: {name: '', cnpj: '', client: {name: ''}},
        unityAux: {name: '', cnpj: '', client: {name: ''}},
        unityList: null,
        userList: [],
        clientList: null,
        deviceList: null,
        currentList: null,
        hardwareId: '',
        hardwareIdAux: '',
        list: [],
        type: {id: 0, name: 'Selecione o tipo'},
        typeList: [{id: 1, name: 'FHT15-2'}, {id: 2, name: 'FHT15-8'}, {id: 3, name: 'MB15-2'}, {id: 4, name: 'MB15-8'}, {id: 5, name: 'CLE'}, {id: 6, name: 'CLX'}, {id: 7, name: 'GATEWAY'}, {id: 8, name: 'FHT7-2'}, {id: 9, name: 'FHT7-8'}, {id: 10, name: 'Maleta'} ],
        token: 'Ativado: ' + new Date(),
        tokenStatus: true,
        description: '',
        coordinates: null,
        systemInfo: null,
        status: null,
        activatedGroups: null,
        errorMsgClient: '',
        errorMsgUnity: '',
        errorMsgHardwareId: '',
        errorMsgType: '',
        errorMsgDescription: '',
        userSearch: '',
        deviceSearch: '',
        showPopup: false,
        userEdit: null,
        searchListTitle: '',
        title: '',
        loadData: false,
        faultOnUpdateDevice: false,
        faultMsg: null,
        accessLevel: AccessLevel
      }
    },
    async created() {
      if(this.device){
        this.title = "Editar Dispositivo"
        if(this.device.unity != null) {
          this.unity = this.device.unity
          this.unityAux = this.unity
          this.usersList = this.unity.userList.reverse().sort(this.compareAddFromClient)
        }
        if(this.device.client != null) {
          this.client = this.device.client
          this.clientAux = this.client
        }
        this.hardwareId = this.device.hardwareId
        this.hardwareIdAux = this.hardwareId
        this.type = this.typeList.find(type => type.id === this.device.type.id)
        this.description = this.device.description
        this.coordinates = this.device.gpsCoordinates
        this.systemInfo = this.device.systemInfo
        this.status = this.device.status
        this.activatedGroups = this.device.activatedGroups
      }else{
        this.title = "Novo Dispositivo"
      }
      this.clientList = await ClientDAO.readAllActiveClients();
      this.unityList = await UnityDAO.readAllActiveUnities();
      this.deviceList = await DeviceDAO.readNotApprovedDevicesToken();
    }
  };
</script>

<style scoped>
  .fixed{
    bottom: 0;
    right: 0;
    position: fixed;
  }
  .tableArea {
    height: 400px;
    position: relative;
  }
  .tableInternalArea {
    position: absolute;
    bottom: 0px;
    top: 45px;
    left: 10px;/*Usado como paddin da tabela */
    right: 10px;/*Usado como paddin da tabela */
    background-color: #1e2227;
    overflow: auto;
  }
  .btnBottomInput{
    width: 400px;
  }
  .inputDisabled{
    background: #757575;
    color: #cecece
  }
  .sideFloat{
    float: right;
  }
  .form{
    display: table;
    width: 80%;
    margin-left: 10%;
    margin-top: 20px;
  }
  .column{
    float: left;
    width: 50%;
  }
  .row{
    width: 100%;
    height: 85px;
  }
  .colLabel{
    width: 30%;
    float: left;
    padding: 5px 30px 5px 0;
    display: table-cell;
    vertical-align: top;
  }
  .colImput{
    position: relative;
    width: 70%;
    float: left;
    padding: 0 5px;
    display: table-cell;
    vertical-align: top;
    margin-bottom: 30px
  }
  .lists{
    display: inline-block;
    padding: 0 10px;
    width: 100%;
    height: 50%;
  }
  .UnidadeArea{
    position: relative;
    width: 50%;
    height: 600px;
    float: left;
    margin-bottom: 15px;
  }
  .userArea{
    position: relative;
    width: 49%;
    height: 600px;
    float: right;
  }
  @media(min-width: 1700px) and (max-height: 400px){
    .fixed{
      position: relative;
    }
  }
  @media(max-width: 1700px) and (max-height: 660px){
    .fixed{
      position: relative;
    }
  }
  @media(max-width: 1700px){
    .form{
      width: 90%;
      margin-left: 5%;
    }
    .column{
      width: 100%;
    }
    .colLabel{
      width: 35%;
    }
    .colImput{
      width: 65%;
      margin-bottom: 40px
    }
    .UnidadeArea{
      width: 100%
    }
    .userArea{
      width: 100%
    }
  }
  @media(max-width: 1000px){
    .colLabel{
      width: 30%;
    }
  }
  @media(max-width: 825px){
    .colLabel{
      width: 100%;
    }
    .colImput{
      width: 100%;
      margin-bottom: 20px
    }
    .sideFloat{
      float: left
    }
    .btnBottomInput{
      width: 100%;
    }
    .tableInternalArea {
      left: 5px;
      right: 5px;
    }
  }
</style>